<template>
  <div class="table-container" v-bind:class="classObject">
    <table
      class="table table-md-responsive"
      v-if="displayedRegistry.length > 0"
    >
      <thead>
        <tr>
          <th>Company<img src="../../../assets/images/sort.png" alt="" class="sort-icon" @click="sort('company_name')"/></th>
          <th>Verified</th>
          <th class="text-center">Status</th>
          <th class="text-center">Effective Date<img class="sort-icon" src="../../../assets/images/sort.png" alt="" @click="sort('start_agreement_date')"/></th>
          <th class="text-center">Website <img class="sort-icon" src="../../../assets/images/sort.png" alt="" @click="sort('company_website')"/></th>
          <th class="text-center">State<img class="sort-icon" src="../../../assets/images/sort.png" alt="" @click="sort('state')"/></th>
          <th class="text-center" >Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(registry, index) in displayedRegistry" :key="index">
          <td v-if="registry.company_logo == null" @click="NDAFormTable(registry)">
            <div class="company-details">
              <div class="company-logo">
                <img src="../../../assets/images/company-logo.png" alt="" />
              </div>
              <h6 class="company-name">{{ registry.company_name }}</h6>
            </div>
          </td>
          <td v-else @click="NDAFormTable(registry)">
            <div class="company-details">
              <div class="company-logo">
                <img :src="registry.company_logo" alt="" />
              </div>
              <h6 class="company-name">{{ registry.company_name }}</h6>
            </div>
          </td>
          <td class="text-center" v-if="registry.global_ndas[0]" @click="NDAFormTable(registry)">
            <div class="company-verification" v-if="(registry.global_ndas[0] && registry.global_ndas[0].verify_status == 1) || (registry.global_ndas[1] && registry.global_ndas[1].verify_status == 1)">
              <img src="../../../assets/images/checkmark.png" alt="" />  
              <img src="../../../assets/images/Check icon.png" class="d-none" alt="" />             
            </div>
            
            <!-- <connectionFav :registry="registry" /> -->
            <!-- <div class="company-link" v-if="registry.global_ndas.length !== 0">
              <img src="../../assets/images/user-tick.png" alt="" />
            </div> -->
          </td>
          <td v-else class="text-center"></td>
          <!-- <td class="text-center" @click="NDAFormTable(registry)">
            <div class="company-size">
              <span> Status</span>
            </div>
          </td> -->
          <td v-if="registry.global_ndas[0] && registry.global_ndas[0].status == 1" class="text-center active">
                <button class="active">Active</button>
          </td>
          <td v-else-if="registry.global_ndas[0] && registry.global_ndas[0].status == 2" class="text-center">
            <button class="terminated">Terminated</button>
          </td>
          <td v-else class="text-center">
                <button class="expired">Expired</button>
          </td>
          <td class="text-center" v-if="registry.global_ndas[0]" @click="NDAFormTable(registry)">
            <div class="company-size">
              <span>
                {{ registry.global_ndas[0].start_agreement_date | moment("MMM D, YYYY") }}
              </span>
              
            </div>
          </td>
          <td v-else class="text-center"></td>
 
          <td class="text-center" @click="NDAFormTable(registry)">
            <div class="company-link">
              <a :href="getCleanURL(registry.company_website)" target="_blank">{{
                 registry.company_website.substring(0, 30) + "..."
              }}</a>
            </div>
          </td>
         
          <td class="text-center" @click="NDAFormTable(registry)">
            <div class="company-size">
              <span>{{ registry.state }} </span>
            </div>
          </td>
          <td class="text-center" >
              <favorite :registry="registry" v-bind:key="favoriteKey" :getDataRegistry="getDataRegistry"/>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="displayedRegistry.length  == 0" class="h-75 d-flex flex-column justify-content-center">
              <p class="no-registry">No registry found.</p>
          </div>
  </div>
</template>

<script>
import favorite from "../../../components/Enterprise/registry/favorite.vue";
//import connectionFav from "./connectionFav.vue";
export default {
  name: "RegistryTable",
  props: {
    displayedRegistry: Array,
    message: Boolean,
    getDataRegistry:{
      type: Function
    }
  },
  components: {
    favorite,
    //connectionFav
  },
  data() {
    return {
      sortField: '',
      sortDirection: '',
      sortColumn: "start_agreement_date",  
      favoriteKey: "",
      isAdmin: "",
    };
  },

  methods: {
    NDAFormTable(registry) {
     
  if (!this.isAdmin) {
    setTimeout(() => {
      this.$root.$emit("dataForCompanyView", registry);
    }, 500);
    this.$router.push({ name: "EnterpriseCompanyAgreements" });
  }
},
    sort(field) {
      this.favoriteKey += 1; 
    if (this.sortField === field) {
      // if already sorting by this field, toggle direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // if sorting by a different field, reset direction to ascending
      this.sortField = field;
      this.sortDirection = 'asc';
      
    }
    // use Array.sort() to sort the displayedRegistry array
    this.displayedRegistry.sort((a, b) => {
      const aVal = field === this.sortColumn ? new Date(a.global_ndas[0][field]) : this.getValue(a, field);
      const bVal = field === this.sortColumn ? new Date(b.global_ndas[0][field]) : this.getValue(b, field);
      
      if (aVal < bVal) {
        return this.sortDirection === 'asc' ? -1 : 1;
      } else if (aVal > bVal) {
        return this.sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
      
    });
    },
    getValue(item, field) {
      // helper function to get the value of a field from an item
      const fieldParts = field.split('.');
      let val = item;
      for (let i = 0; i < fieldParts.length; i++) {
        val = val[fieldParts[i]];
      }
      return val;
    },
    getCleanURL(url) {
    // Remove search parameters from URL
    const cleanURL = url.split('?')[0];
    // Add http or https protocol if missing
    if (!cleanURL.startsWith('http://') && !cleanURL.startsWith('https://')) {
      return `http://${cleanURL}`;
    }
    return cleanURL;
  },
  },
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      }
    },
  },
  mounted() {
    if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    }
  },
};
</script>
