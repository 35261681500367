<template>
    <div class="registry-sign-up-form">
        <iframe
        ref="iframeContent"
        frameborder="0"
        height="900"
        scrolling="yes"
        :src="foxitData"
        width="100%"
        id="sigForm"
        >
        </iframe>
        <div
        style="font-family: Helvetica, Arial, Verdana, sans-serif;line-height:13px !important;text-align:center;margin-top: 6px !important;"
        ></div>
    </div>
</template>
  
<script>
export default {
name: "SignSuccess",
data() {
    return {
    foxitData: "",
    };
},
mounted() {
    this.$root.$on("signSuccessView", (data) => {
    this.foxitData = data;
    });
},
};
</script>