<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <div class="home-registry-wrapper">
            <PDFViewer/>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  import LeftMenu from "../../../components/Enterprise/leftmenu/LeftMenu.vue";
  import Navbar from "../../../components/Enterprise/header/Navbar.vue";
  import PDFViewer from "../../../components/admin/Foxit/PDFViewer";
  import { mapGetters } from "vuex";
  
  export default {
    name: "PDFView",
    data() {
    return {

      showloader: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods:{
   
  },
  
    components: {
      Navbar,
      LeftMenu,
      PDFViewer
    },
    
  };
  </script>
  <style  scoped>
  .lds-dual-ring-registry {
    position: absolute;
    z-index: 999;
    padding-top: 50% !important;
  }
   </style>