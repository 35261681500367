<template>
  <!-- <lightbox :items="images"></lightbox> -->
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
        <div class="filter-wrapper">
          <RegistryFilters @showChange="gettingValueChanged" />
        </div>
        <div class="home-registry-wrapper">
          <RegistryTop :count="count" 
              :searched="filter.search"
              :getDataRegistry="getDataRegistry"
          />
          <!-- <RegistryGrid /> -->
          <!-- <RegistryTable v-if="showLoader == 0" :displayedRegistry="registry" /> -->
          <div
            v-if="showLoader == 1"
            class="lds-dual-ring-registry"
            id="loading"
          ></div>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              id="registry-table"
              v-bind:class="{ active: tabindex == 0 , show: tabindex == 0}"
              role="tabpanel"
              aria-labelledby="registry-table-tab"
            >
              <RegistryTable :message="parentMessage"
                v-if="showLoader == 0"
                :displayedRegistry="registry"
                :getDataRegistry="getDataRegistry"
              />
            </div>
            <div
              class="tab-pane fade"
              id="registry-grid"
              v-bind:class="{ active: tabindex == 1 , show: tabindex == 1}"
              role="tabpanel"
              aria-labelledby="registry-grid-tab"
            >
              <RegistryGrid
                v-if="showLoader == 0"
                :displayedRegistry="registry"
                :message="parentMessage"
              />
            </div>
          </div>
            <RegistryBottom v-if="showLoader == 0" :page="pager" :count="count" :size="size" />    
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import RegistryTop from "../components/registry/RegistryTop.vue";
import RegistryGrid from "../components/registry/RegistryGrid.vue";
import RegistryTable from "../components/registry/RegistryTable.vue";
import RegistryBottom from "../components/registry/RegistryBottom.vue";
import Navbar from "../components/header/Navbar.vue";
import RegistryFilters from "../components/registry/RegistryFilter.vue";

export default {
  name: "Home",

  components: {
    Navbar,
    LeftMenu,
    RegistryTop,
    RegistryGrid,
    RegistryTable,
    RegistryBottom,
    RegistryFilters,
  },

  data: function() {
    return {
      parentMessage: false,
      errorMessage: null,
      registry: [],
      showLoader: 0,
      page: 1,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      size: 0,
      tabindex: 0,
      images: [
        "https://i.wifegeek.com/200426/f9459c52.jpg",
        "https://i.wifegeek.com/200426/5ce1e1c7.jpg",
        "https://i.wifegeek.com/200426/5fa51df3.jpg",
        "https://i.wifegeek.com/200426/663181fe.jpg",
        "https://i.wifegeek.com/200426/2d110780.jpg",
        "https://i.wifegeek.com/200426/e73cd3fa.jpg",
        "https://i.wifegeek.com/200426/15160d6e.jpg",
        "https://i.wifegeek.com/200426/d0c881ae.jpg",
        "https://i.wifegeek.com/200426/a154fc3d.jpg",
        "https://i.wifegeek.com/200426/71d3aa60.jpg",
        "https://i.wifegeek.com/200426/d17ce9a0.jpg",
        "https://i.wifegeek.com/200426/7c4deca9.jpg",
        "https://i.wifegeek.com/200426/64672676.jpg",
        "https://i.wifegeek.com/200426/de6ab9c6.jpg",
        "https://i.wifegeek.com/200426/d8bcb6a7.jpg",
        "https://i.wifegeek.com/200426/4085d03b.jpg",
        "https://i.wifegeek.com/200426/177ef44c.jpg",
        "https://i.wifegeek.com/200426/d74d9040.jpg",
        "https://i.wifegeek.com/200426/81e24a47.jpg",
        "https://i.wifegeek.com/200426/43e2e8bb.jpg",
      ],
      filter: {},
      pageSize: "15",
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    gettingValueChanged(value) {
      this.parentMessage = value
    },
    getDataRegistry() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}registry/listing?page=${
            this.page
          }&size=${this.pageSize ? this.pageSize : ""}&verified=${
            this.filter.verified ? this.filter.verified : ""
          }&favourite=${
            this.filter.favourite ? this.filter.favourite : ""
          }&connection=${
            this.filter.connection ? this.filter.connection : ""
          }&state=${
            this.filter.state ? this.filter.state : ""
          }&company_size=${
            this.filter.size ? this.filter.size : ""
          }&expiration_date=${
            this.filter.expiration_date ? this.filter.expiration_date : ""
          }&termination_date=${
            this.filter.termination_date ? this.filter.termination_date : ""
          }&effective_date=${
            this.filter.effective_date ? this.filter.effective_date : ""
          }&status=${
            this.filter.status ? this.filter.status : ""
          }&search=${
            this.filter.search ? this.filter.search : ""
          }`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.registry = response.data.data.listing;
            this.pager = response.data.data;
            this.count = response.data.data.totalItems;
            this.size = response.data.data.size;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    verifyResend() {
      this.success = this.error = null;
      this.sendVerifyResendRequest()
        .then(() => {
          this.success =
            "A fresh verification link has been sent to your email address.";
        })
        .catch((error) => {
          this.error = "Error sending verification link.";
          console.log(error.response);
        });
    },
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.currentPage || page == this.pager.totalPages + 1) {
          this.showLoader = 1;
          axios
            .post(
              `${process.env.VUE_APP_API_URL}registry/listing?page=${
                page ? page : ""
              }&size=${this.pageSize ? this.pageSize : ""}&verified=${
                this.filter.verified ? this.filter.verified : ""
              }&favourite=${
                this.filter.favourite ? this.filter.favourite : ""
              }&connection=${
                this.filter.connection ? this.filter.connection : ""
              }&state=${
                this.filter.state ? this.filter.state : ""
              }&company_size=${this.filter.size ? this.filter.size : ""
              }&expiration_date=${
                this.filter.expiration_date ? this.filter.expiration_date : ""
              }&termination_date=${
                this.filter.termination_date ? this.filter.termination_date : ""
              }&effective_date=${
                this.filter.effective_date ? this.filter.effective_date : ""
              }&status=${
                this.filter.status ? this.filter.status : ""
              }&search=${
                this.filter.search ? this.filter.search : ""
              }`,
              {}
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.registry = response.data.data.listing;
                this.pager = response.data.data;
                this.count = response.data.data.totalItems;
                this.size = response.data.data.size;
                this.showLoader = 0;
              } else {
                this.errorMessage = response.data.message;
                this.showLoader = 0;
              }
            });
        }
      },
    },
  },
  mounted() {
    this.$root.$on("filtersCallForRegistry", (data) => {
      this.filter = data;
      if (data.pageSize) {
        this.pageSize = data.pageSize.text;
      }
      this.getDataRegistry();
    });
    this.$root.$on("filtersForRegistry", (data) => {
    this.filter = data;
    
    this.getDataRegistry();
  });
    this.$root.$on("viewHandle", (data) => {
      this.tabindex = data;
    });
  },
  created() {},
};
</script>