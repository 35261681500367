<template>
  <div class="registry-wrapper-top">
    <div class="heading">
      <h5>Registry</h5>
      <span>{{ count }} Companies</span>
    </div>
    <div class="buttons">
      <div class="form-group mb-0 mx-2" @keyup.enter="search($event)">
        <button class="search-icon" @click="search()">
          <img src="../../assets/images/search.png" alt="" />
        </button>
        <input
          class="form-control"
          type="search"
          placeholder="Type Here..."
          aria-label="Search"
          v-model="filter.search"
          @input="search($event)"
        />
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active img-wrapper"
            id="registry-table-tab"
            data-toggle="tab"
            data-target="#registry-table"
            type="button"
            ref="tableView"
            role="tab"
            @click="tabs(0)"
            aria-controls="registry-table"
            aria-selected="true"
          >
            <img src="../../assets/images/table-icon.png" alt="" />
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link img-wrapper"
            id="registry-grid-tab"
            data-toggle="tab"
            data-target="#registry-grid"
            type="button"
            role="tab"
            ref="gridView"
            aria-controls="registry-grid"
            aria-selected="false"
            @click="tabs(1)"
          >
            <img src="../../assets/images/grid-icon.png" alt="" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistryTop",
  props: {
    count: Number,
    searched: String,
    getDataRegistry:{
      typeof: Function
    }
  },
  data: function (){
    return{
      filter: {
          search: "",
        },
    }
  },
  methods: {
	  tabs(tabindex) {
      this.$root.$emit('viewHandle', tabindex)
      // Refresh listing data on click on tab to get instantly favourite registry
      if (this.getDataRegistry) {
          this.getDataRegistry(); 
        }
  	},
    search() {
        this.$root.$emit("filtersForRegistry", this.filter);
      },
	}
};
</script>

<style></style>
