<template>
  <!-- <div> -->
  <div class="container-fluid filter-content">
    <div class="row no-gutters p-0 px-1 mb-2 justify-content-between">
      <div class="col-12 d-flex ">
        <h5 class="mb-md-1">Filters</h5>
        <button v-if="isAdmin" class="custom-btn filter-btn  mt-0 ml-auto" @click="clearAdminFilter">
          Clear Filter
        </button>
        <button v-else class="custom-btn filter-btn  mt-0 ml-auto" @click="clearFilter">
          Clear Filter
        </button>
        <span class="toggle float-right px-2 cur-pointer"
         @click="toggleVisible" 
              ><i class="fa fa-angle-up" aria-hidden="true" v-if="visible"></i>
              <i class="fa fa-angle-down" aria-hidden="true" v-else></i>
        </span>
      </div>
    </div>
  
    <div v-if="isAdmin">
      <div class="row align-items-center justify-content-center" v-if="visible">
      <!-- <div class="col-12 col-md-12 col-lg-2 px-0 pr-md-2 align-self-end pb-1">
       
      </div> -->

      <div class="col-12 col-lg-4 p-0 pr-2 ">
        <div class="form-group">
          <div class="date-pick">
            <!-- <div class="w-100 float-left dark ">
                   <datepicker v-model="filter.effective_date" @input="setSelected" class="date dark" placeholder="Effective Date"  ></datepicker>
            </div>
            <div class="float-right">
              <i class="pt-1 pr-2 i fa fa-calendar"></i>
            </div> -->
            <VueDatePicker class="h-100"  placeholder="Register Date" v-model="filter.effective_date" @input="setSelected" :color="color"/>
          </div>
        </div>
      </div>
      <div class="col-12  col-lg-4 p-0 pr-2 ">
        <div class="form-group">
          <div class="date-pick">
            <!-- <div class=" w-100 float-left dark ">
                   <datepicker class="date dark" v-model="filter.expiration_date" @input="setSelected" placeholder="Expiration Date" ></datepicker>
            </div>
            <div class="float-right dark ">
              <i class="pt-1 pr-2 i fa fa-calendar"></i>
            </div> -->
            <VueDatePicker class="h-100"  @input="setSelected" placeholder="Verification Date" v-model="filter.expiration_date" :color="color"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 p-0 pr-2 ">
        <div class="form-group">
          <div class="date-pick">
            <!-- <div class=" w-100 float-left dark ">
               <datepicker class="date dark" v-model="filter.termination_date" @input="setSelected" placeholder="Termination Date" ></datepicker>
            </div>
            <div class="float-right dark ">
              <i class="pt-1 pr-2 i fa fa-calendar"></i>
            </div> -->
            <VueDatePicker class="h-100"  v-model="filter.termination_date" @input="setSelected" placeholder="Last Active Date" :color="color"/>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row " v-if="visible">
      <!-- <div class="col-12 col-md-3 col-lg-2 px-0 pr-md-2 align-self-start pt-1">
      
      </div> -->

      <div class="col-12 col-lg-4 p-0 pr-2 ">
        <div class="form-group">
          <!-- <label for="name">
            <div class="change-icon">
              <span class="text">State</span>
            </div>
          </label> -->
          <v-select
            v-model="statusText"
            :options="statusList"
            class="custom-select p-0"
            label="text"
            :placeholder="statusPlaceholder"
            @input="setSelectedStatus"
          />
        </div>
      </div>
      <div class="col-12  col-lg-4 p-0 pr-2 " v-if="!isAdmin">
        <div class="form-group">
          <!-- <label for="name">
            <div class="change-icon">
              <span class="text">State</span>
            </div>
          </label> -->
          <v-select
            v-model="filter.state"
            :options="stateList"
            class="custom-select p-0"
            label="name"
            :placeholder="statePlaceholder"
            @input="setSelected"
          />
        </div>
      </div>
      <div class="col-12  col-lg-4 p-0 pr-2" v-if="!isAdmin">
        <div class="form-group">
          <!-- <label for="name">
            <div class="change-icon">
              <span class="text">Size</span>
            </div>
          </label> -->
          <v-select
            v-model="filter.size"
            :options="sizeList"
            label="name"
            class="custom-select p-0"
            :placeholder="sizePlaceholder"
            @input="setSelectedSize"
          />
          <!-- <select
              v-model="$v.details.size.$model"
              class="form-control"
              :class="{
                'is-invalid': $v.details.size.$error,
                'is-valid': !$v.details.size.$invalid,
              }"
            >
              <option value="" disabled>
                Select Company Size
              </option>
              <option v-for="(item, index) in sizeOptions" :key="index">
                {{ item.size }}
              </option>
            </select> -->
        </div>
      </div>
    </div>
    <!-- <div class="row col-12 pr-lg-5">
      <button class="custom-btn filter-btn mr-lg-2 mt-0 ml-auto" @click="clearFilter">
        Clear Filter
      </button>
    </div> -->
    </div>
    
    <div v-else>
      <div class="row align-items-center justify-content-center" v-if="visible">
      <div class="col-12 col-md-12 col-lg-2 px-0 pr-md-2 align-self-end pb-1">
        <form class="registry-radio-btns pb-2">
          <div>
            <input type="checkbox"
             id="verified" 
             value="Verified" 
             name="registry_filter" 
             @click="getRegistryFilters('verified', $event)">
            <label for="verified">
              <span></span> Verified
            </label>
            <!-- Verified -->
            <!-- <input
              type="checkbox"
              id="verified"
              name="registry_filter"
              value="Verified"
              class="check"
              @click="getRegistryFilters('verified', $event)"
            />
            <label class="dis" for="verified">Verified</label> -->
          </div>
          <div class="d-block d-lg-none d-flex">
            <input 
            type="checkbox" 
            id="favourite" 
            @click="getRegistryFilters('favourite', $event)">
            <label for="favourite">
              <span></span>Favorite
            </label>
            <!-- Favourite -->
            <!-- <input
              type="checkbox"
              id="favourite"
              name="registry_filter"
              value="Favourite"
              class="check"
              @click="getRegistryFilters('favourite', $event)"
            />
            <label class="pl-1 dis" for="favourite">Favorite</label> -->
          </div>
          <!-- Conecction -->
          <!-- <div>
            <input
              type="checkbox"
              id="connection"
              name="registry_filter"
              value="Connection"
              @click="getRegistryFilters('connection', $event)"
            />
            <label for="connection">Connection</label>
          </div> -->
        </form>
      </div>
      <div class="col-12 col-lg-3 p-0 pr-2 ">
        <div class="form-group">
          <div class="date-pick">
            <!-- <div class="w-100 float-left dark ">
                   <datepicker v-model="filter.effective_date" @input="setSelected" class="date dark" placeholder="Effective Date"  ></datepicker>
            </div>
            <div class="float-right">
              <i class="pt-1 pr-2 i fa fa-calendar"></i>
            </div> -->
            <VueDatePicker class="h-100"  placeholder="Effective Date" v-model="filter.effective_date" @input="setSelected" :color="color"/>
            
          </div>
        </div>
      </div>
      <div class="col-12  col-lg-3 p-0 pr-2 ">
        <div class="form-group">
          <div class="date-pick">
            <!-- <div class=" w-100 float-left dark ">
                   <datepicker class="date dark" v-model="filter.expiration_date" @input="setSelected" placeholder="Expiration Date" ></datepicker>
            </div>
            <div class="float-right dark ">
              <i class="pt-1 pr-2 i fa fa-calendar"></i>
            </div> -->
            <VueDatePicker class="h-100"  @input="setSelected" placeholder="Expiration Date" v-model="filter.expiration_date" :color="color"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 p-0 pr-2 ">
        <div class="form-group">
          <div class="date-pick">
            <!-- <div class=" w-100 float-left dark ">
               <datepicker class="date dark" v-model="filter.termination_date" @input="setSelected" placeholder="Termination Date" ></datepicker>
            </div>
            <div class="float-right dark ">
              <i class="pt-1 pr-2 i fa fa-calendar"></i>
            </div> -->
            <VueDatePicker class="h-100"  v-model="filter.termination_date" @input="setSelected" placeholder="Termination Date" :color="color"/>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row align-items-center justify-content-center" v-if="visible">
      <div class="col-12 col-md-3 col-lg-2 px-0 pr-md-2 align-self-start pt-1">
        <form class="registry-radio-btns">
          <div class="d-none d-lg-block favourites">
            <input 
            type="checkbox" 
            id="favourites" 
            value="Favourite" 
            name="registry_filter" 
            @click="getRegistryFilters('favourite', $event)">
            <label for="favourites">
              <span></span> Favorite
            </label>
            <!-- favourite -->
            <!-- <input
              type="checkbox"
              id="favourites"
              name="registry_filter"
              value="Favourite"
              class="check"
              
              @click="getRegistryFilters('favourite', $event)"
            />
            <label class="pl-1 dis" for="favourites">Favorite</label> -->
            
          </div>
          <!-- Conecction -->
          <!-- <div>
            <input
              type="checkbox"
              id="connection"
              name="registry_filter"
              value="Connection"
              @click="getRegistryFilters('connection', $event)"
            />
            <label for="connection">Connection</label>
          </div> -->
        </form>
        
      </div>
      <div class="col-12 col-lg-3 p-0 pr-2 ">
        <div class="form-group">
          <!-- <label for="name">
            <div class="change-icon">
              <span class="text">State</span>
            </div>
          </label> -->
          <v-select
            v-model="statusText"
            :options="statusList"
            class="custom-select p-0"
            label="text"
            :placeholder="statusPlaceholder"
            @input="setSelectedStatus"
          />
        </div>
      </div>
      <div class="col-12  col-lg-3 p-0 pr-2 ">
        <div class="form-group">
          <!-- <label for="name">
            <div class="change-icon">
              <span class="text">State</span>
            </div>
          </label> -->
          <v-select
            v-model="filter.state"
            :options="stateList"
            class="custom-select p-0"
            label="name"
            :placeholder="statePlaceholder"
            @input="setSelected"
          />
        </div>
      </div>
      <div class="col-12  col-lg-3 p-0 pr-2">
        <div class="form-group">
          <!-- <label for="name">
            <div class="change-icon">
              <span class="text">Size</span>
            </div>
          </label> -->
          <v-select
            v-model="filter.size"
            :options="sizeList"
            label="name"
            class="custom-select p-0"
            :placeholder="sizePlaceholder"
            @input="setSelectedSize"
          />
          <!-- <select
              v-model="$v.details.size.$model"
              class="form-control"
              :class="{
                'is-invalid': $v.details.size.$error,
                'is-valid': !$v.details.size.$invalid,
              }"
            >
              <option value="" disabled>
                Select Company Size
              </option>
              <option v-for="(item, index) in sizeOptions" :key="index">
                {{ item.size }}
              </option>
            </select> -->
        </div>
      </div>
    </div>
    <!-- <div class="row col-12 pr-lg-5">
      <button class="custom-btn filter-btn mr-lg-2 mt-0 ml-auto" @click="clearFilter">
        Clear Filter
      </button>
    </div> -->
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
// import Datepicker from "vuejs-datepicker";
let states = require("../../../assets/js/states");
let size = require("../../../assets/js/size");
export default {
  name: "RegistryFilter",
  components: {
    // Datepicker,
    VueDatePicker,
  },
  data: function() {
    return {
      visible: false,
      color: 'none',
      stateList: states,
      statusText: "",
      isAdmin: false,
      isVerfier: false,
      statusList: [
        { text: "Active", id: "1" },
        { text: "Terminated", id: "2"},
        { text: "Expired", id: "0" }
      ],
      statePlaceholder: "Select State",
      sizePlaceholder: "Select Size",
      statusPlaceholder: "Select Status",
      sizeList: size,
      filterText: "Select",
      filter: {
        verified: "",
        favourite: "",
        connection: "",
        state: "",
        size: "",
        effective_date: null,
        expiration_date: null,
        termination_date:  null,
        status: ""
      },
    };
  },
    methods: {
    toggleVisible(){
      this.visible = !this.visible
      this.$emit("showChange", this.visible)
    },
    clearFilter() {
      this.filter = {};
      //document.getElementById("connection").checked = false;
      if(this.visible == true){
        document.getElementById("verified").checked = false;
        document.getElementById("favourite").checked = false;
        document.getElementById("favourites").checked = false;
      }
      this.statusText = "";
      this.$root.$emit("filtersCallForRegistry", this.filter);
      this.$root.$emit("filtersCallForUser", this.filter);

    },
    clearAdminFilter() {
      this.filter = {};
      this.statusText = "";
      this.$root.$emit("filtersCallForRegistry", this.filter);
      this.$root.$emit("filtersCallForUser", this.filter);

    },
    setSelectedStatus(value){
      if (value != null) {
        this.filter.status = value.id;
        this.$root.$emit("filtersCallForRegistry", this.filter);
        this.$root.$emit("filtersCallForUser", this.filter);

      } else {
        this.filter.status = ""
        this.$root.$emit("filtersCallForRegistry", this.filter);
        this.$root.$emit("filtersCallForUser", this.filter);

      }
    },
    setSelected(value) {
      if (value != null) {
        this.filter.state = value.name;
        this.$root.$emit("filtersCallForRegistry", this.filter);
        this.$root.$emit("filtersCallForUser", this.filter);

      } else {
        this.$root.$emit("filtersCallForRegistry", this.filter);
        this.$root.$emit("filtersCallForUser", this.filter);

      }
    },
    setSelectedSize(value) {
      if (value != null) {
        this.filter.size = value.name;
        this.$root.$emit("filtersCallForRegistry", this.filter);
        this.$root.$emit("filtersCallForUser", this.filter);

      } else {
        this.$root.$emit("filtersCallForRegistry", this.filter);
        this.$root.$emit("filtersCallForUser", this.filter);

      }
    },

    getRegistryFilters(value, event) {
      if (value === "verified") {
        if (event.target.checked) {
          this.filter.verified = 1;
        } else {
          this.filter.verified = 0;
        }
        this.$root.$emit("filtersCallForRegistry", this.filter);
      }
      if (value === "favourite") {
        if (event.target.checked) {
          this.filter.favourite = 1;
        } else {
          this.filter.favourite = 0;
        }
        this.$root.$emit("filtersCallForRegistry", this.filter);
      }
      // if (value === "connection") {
      //   if (event.target.checked) {
      //     this.filter.connection = 1;
      //   } else {
      //     this.filter.connection = 0;
      //   }
      //   this.$root.$emit("filtersCallForRegistry", this.filter);
      // }
    },
  },
  mounted() {

    if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    } else if (localStorage.getItem("permissions") == 4) {
      this.isVerfier = true;
    }
  },
};
</script>

<style scoped>
.form-group {
    margin-bottom: 0.5em;
}
.date-pick {
  height: 45px;
  width: 100%;
  border: 1px solid var(--light-gray)!important;
  padding-top: auto;
  border-radius: 0.25rem;
  position: relative;
}
.i {
  height: 20px;
  position: absolute;
  right: 0;
  padding-right: 3px;
}
.custom-select {
  border: 1px solid var(--light-gray)!important;
  border-radius: 0.25rem;
}

/* Custom Css for check inputs start */
/* .dis{
  display: contents!important;
} */
label {
  display: flex; 
  align-items: center; 
  /* color: #9e9e9e; */
  position: relative;
}
input {
  width: 0;
  height: 0;
  opacity: 0;
}
label > span {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  border: 2px solid #9e9e9e;
  margin-right: 10px;
  border-radius: 3px;
  transition: all .3s;
  cursor: pointer;
}
input:checked + label > span {
  border: 7px solid #ffee00;
  animation: bounce 250ms;
}
input:checked + label > span::before {
  content: '';
  position: absolute;
  top: 6px;
  left: 4px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checked-box 125ms 250ms forwards;
}
@keyframes checked-box {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate(0,0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    border-color: #212121;
    transform: translate(0,0) rotate(45deg);
  }
  100% {
    margin-top: 6px;
    width: 4px;
    height: 8px;
    border-color: #212121;
    transform: translate(0,-8px) rotate(45deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(.7);
  }
  100% {
    transform: scale(1);
  }
}
/* Custom Css for check inputs end */

</style>
