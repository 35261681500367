<template>
  <div class="table-content" v-bind:class="classObject">
    <div class="container-fluid">
      <div class="row" v-if="displayedRegistry.length > 0">
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-3 pl-sm-0 pr-md-2"
          v-for="(registryGrid, index) in displayedRegistry"
          :key="index"
        >
          <div class="registry-content">
            <div class="row no-gutters">
              <div class="col-3 pl-1">
                <div
                  class="image"
                  v-if="registryGrid.company_logo == null"
                  @click="NDAFormTable(registryGrid)"
                >
                  <img src="../../../assets/images/company-logo.png" alt="" />
                </div>
                <div class="image" v-else @click="NDAFormTable(registryGrid)">
                  <img :src="registryGrid.company_logo" alt="" />
                </div>
              </div>
              <div class="col-7 align-self-end px-2">
                <div class="link float-left" @click="NDAFormTable(registryGrid)">
                  <div class="name" @click="NDAFormTable(registryGrid)">
                    <h4>{{ registryGrid.company_name }}</h4>
                    <div
                      @click="NDAFormTable(registryGrid)"
                      class="company-verification float-right pl-2"
                      v-if="registryGrid.global_ndas[0] && registryGrid.global_ndas[0].verify_status == 1"
                    >
                      <img src="../../../assets/images/checkmark.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 pl-md-1 pl-1">
                <favorite :registry="registryGrid" class="pt-1" />
              </div>
            </div>

            <div class="status pt-1 pl-2" @click="NDAFormTable(registryGrid)">
              <h6 v-if="(registryGrid.global_ndas[0] && registryGrid.global_ndas[0].status == 1)" class="text-success">
                Active
              </h6>
              <h6 v-else-if="(registryGrid.global_ndas[0] && registryGrid.global_ndas[0].status == 2)" class="text-danger">
                Terminated
              </h6>
              <h6 v-else class="text-muted">Expired</h6>
            </div>
            <div class="date pt-1 pl-2" @click="NDAFormTable(registryGrid)">
              <span class="pr-2">Date Signed:</span>
              <span>{{ registryGrid.global_ndas[0] && registryGrid.global_ndas[0].createdAt | moment("MMM D, YYYY") }}</span>
            </div>
                      <!-- <div class="image" v-if="registryGrid.company_logo == null" @click="NDAFormTable">
                        <img src="../../assets/images/company-logo.png" alt="" />
                      </div>
                      <div class="image" v-else @click="NDAFormTable">
                        <img :src="registryGrid.company_logo" alt="" />
                      </div>
                      <div class="row">
                  <div class="col-xs-6">
                    <favorite :registry="registryGrid" class="pt-1"/>
                  </div>
                  <div class="company-verification col-xs-6 mt-1" v-if="registryGrid.global_ndas[0].verify_status == 1">
                    <img src="../../assets/images/checkmark.png" alt="" />
                  </div>
              </div>
                      <div class="link" @click="NDAFormTable">
                        <a :href="registryGrid.company_website" target="_blank">{{
                          registryGrid.company_website.substring(0, 30) + "..."
                        }}</a>
                      </div>
                      <div class="location" @click="NDAFormTable">
                        <p>
                          {{ registryGrid.company_address }}
                        </p>
                      </div>
                      <div class="seperator" @click="NDAFormTable">
                        <img src="../../assets/images/line.png" alt="" />
                      </div> -->
                      <!-- <div class="name" @click="NDAFormTable">
                        <img
                          v-if="registryGrid.status !== 0"
                          src="../../assets/images/checkmark.png"
                          alt=""
                        />
                        <h4>{{ registryGrid.company_name }}</h4>
                      </div> -->
          </div>
        </div>
      </div>
      <div v-else class="h-75 d-flex flex-column justify-content-center">
              <p class="no-registry">No registry found.</p>
          </div>
    </div>
  </div>
</template>

<script>
import favorite from "../../../components/Enterprise/registry/favorite.vue";

export default {
  name: "RegistryGrid",
  data() {
    return {
      isAdmin: "",
    };
  },
  components: {
    favorite,
  },
  props: {
    displayedRegistry: Array,
    message: Boolean,
  },
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message
      }
    }
  },
  methods: {
    NDAFormTable(registryGrid) {
  if (!this.isAdmin) {
    setTimeout(() => {
      this.$root.$emit("dataForCompanyView", registryGrid);
    }, 500);
    this.$router.push({ name: "EnterpriseCompanyAgreements" });
  }
},
  },
  mounted() {
    if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    } 
},
};
</script>

<style></style>
