<template>
    <div class="registry-sign-up-form">

      <div v-if="showLoader == 1">
        <div
            class="lds-dual-ring-registry"
            id="loading"
            >
          </div>
      </div>

        <iframe
        ref="iframeContent"
        frameborder="0"
        height="900"
        scrolling="yes"
        :src="foxitData"
        width="100%"
        id="sigForm"
      
      >
      </iframe>
      <div
        style="font-family: Helvetica, Arial, Verdana, sans-serif;line-height:13px !important;text-align:center;margin-top: 6px !important;"
      ></div>
    </div>
  </template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

  export default {
    name: "PDFViewer",
    data() {
      return {
        foxitData: "",
        showLoader: 1,
        event: "",
        folderId: "",
      };
    },
    computed: {
    ...mapGetters("auth", ["user"]),
  },
    methods: {
    ...mapActions("auth", ["getUserData"]),
    UpdateFormStatus: async function() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("folderId", this.folderId);
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/agreement/signSuccess", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.foxitData = response.data.data.s3_url;
            console.log(this.foxitData)
            // setTimeout(() => {
            //   this.$root.$emit("signSuccessView", this.foxitData);
            // }, 500);
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
            this.showLoader = 0;
          }
        });
    },
   
    getLinkUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.folderId = urlParams.get("folderId");
      this.event = urlParams.get("event");
      if (this.folderId != null) {
        this.UpdateFormStatus();
      } 
      // else {
      //   this.$swal("Your already signed the agreement.", "info");      }
    },
  },
  // beforeMount(){
  //   this.getUserData();
  // },
    mounted() {
      this.$root.$on("foxitTemplateView", (data) => {
        this.foxitData = data;
        this.showLoader = 0;

      });
      this.$root.$on("createTemplate", (templateData) => {
        console.log(templateData)
        this.foxitData = templateData;
        this.showLoader = 0;

      });
      this.getLinkUrl(1);
    },
  };
  </script>