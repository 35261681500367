<template>
  <div
   
    class="company-link"
    v-if="likeStatus == false"
  >
    <img  @click="likeUlike(1, registry.id)" class="heart" :src="imageSrcUnlike" alt="" />
  </div>
  <div  class="company-link" v-else>
    <img @click="likeUlike(2, registry.id)" class="heart" :src="imageSrclike" alt="" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "favorite",
  data() {
    return {
      imageSrcUnlike: require("../../../assets/images/heart-empty.png"),
      imageSrclike: require("../../../assets/images/heart-red.png"),
      likeStatus: false,
    };
  },
  props: {
    registry: Object,
    count: Number,
    getDataRegistry:{
      typeof: Function
    }
  },
  methods: {
    likeUlike(status, like_registry_id) {
      if (status == 1) {
        this.likeStatus = true;
      } else if (status == 2) {
        this.likeStatus = false;
      }
      var formData = new FormData();
      formData.append("status", status);
      formData.append("like_registry_id", like_registry_id);
      axios.post(process.env.VUE_APP_API_URL + "registry/favouriteReaction", formData)
          .then((response) => {
            const statusCode = response.data.statusCode || 500;
            if (statusCode === 200) {
              this.$emit('update-data');
              // if (this.getDataRegistry) {
              //   this.getDataRegistry(); 
              // }
              console.log("updated");
            }
          })
          .catch((error) => {
            console.log("Error occurred:", error);
          });
    },
  },
  mounted() {
    if (this.registry.registry_favourites.length == 0) {
      this.likeStatus = false;
    } else {
      this.likeStatus = true;
    }
  },
};
</script>

<style>
.heart{
  height: 30px!important;
}
</style>
